@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

body {
  color: theme('colors.green.dark');
  background-color: theme('colors.white');
  font-family: 'Raleway', sans-serif;
  background-image: url('../images/bg.gif');
  background-size: cover;
}

@media (max-width: 1242px) {
  body {
    background-image: url('../images/app.bg.gif');
    background-position: top;
    background-repeat: no-repeat;
  }

  #cbd {
    width: 100%;
  }
}

@media (min-width: 1243px) {
  #cbd {
    width: 375px;
  }
}

@media (max-width: 375px) {
  body {
    background-image: url('../images/app.bg.gif');
    background-position: top;
    background-repeat: no-repeat;
  }
}

#vertical-line {
  transform: rotate(90deg) scale(0.75, 1) translate(35%, 50%);
}

#home-link {
  color: #46795f;
}

#home-link:hover {
  color: #8da492;
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Raleway-Regular.ttf') format('truetype');
}

/************************************************/
/****************** NAVIGATION ******************/
/************************************************/
nav {
  color: #1d1f20;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

/*** INPUT ***/
nav input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

/*** LABEL ***/
nav label {
  cursor: pointer;
  display: block;
  font: 2em/1 'Oswald', sans-serif;
  padding: 0.5em;
}

/* Hamburger Icon */
#menu-icon,
#menu-icon:before,
#menu-icon:after {
  background: #1d1f20;
  border-radius: 0.05em;
  height: 0.2em;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

#menu-icon {
  display: inline-block;
  margin: 0.4em 0;
  max-width: 1em;
  position: relative;
}

nav label #menu-icon {
  float: right;
}

#menu-icon:before,
#menu-icon:after {
  content: '';
  left: 0;
  position: absolute;
}

#menu-icon:before {
  top: -0.4em;
}

#menu-icon:after {
  bottom: -0.4em;
}

/* Close Icon */
nav input[type='checkbox']:checked + label #menu-icon {
  background: transparent;
}

nav input[type='checkbox']:checked + label #menu-icon:before {
  top: 0;
  transform: rotate(-45deg);
}

nav input[type='checkbox']:checked + label #menu-icon:after {
  bottom: 0;
  transform: rotate(45deg);
}

/*** MENU ***/
/* Overlay */
nav input:checked ~ #overlay {
  background: theme('colors.green.lighter');
  bottom: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: -1;
  transition: 0.5s;
  overflow-y: hidden;
}

/* List */
nav ul {
  font-size: 1.5em;
  list-style: none;
  margin: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
}

nav input:checked ~ ul {
  margin: 1em;
  max-height: inherit;
  opacity: 1;
}

nav ul > li {
  margin: 5em 0;
}

nav ul a {
  color: theme('colors.white');
  text-decoration: none;
}

nav ul a:hover {
  text-decoration: underline;
}

#navbar {
  position: fixed;
  transition: top 0.3s;
  width: 100%;
}
